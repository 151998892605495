<template>
  <div class="membership-details">
    <div class="membership-details-content">
      <div>
        <div class="membership-details-balance">
          <p class="is-marginless">
            Your balance<br>
            <strong>{{ membership.balance | formatHours }}</strong>
          </p>
        </div>
      </div>

      <div v-if="subscription">
        <p>
          Status:<br>
          <strong>{{ subscription.status | formatStatus }}</strong>
        </p>

        <p>
          Current plan:<br>
          <strong>{{ subscription.plan.hours | formatHours }} per month for {{ subscription.plan.price | formatCurrency }}</strong><br>
          <a :href="updateMembershipURL" class="goo-link">Manage plan</a>
        </p>

        <p v-if="membership.status !== 'canceled'">
          Next billing date:<br>
          <strong>Your plan will automatically renew on {{ subscription.renewalDate | formatDate }}</strong>
        </p>

        <div v-if="membership.status === 'canceled'" class="goo-button-group">
          <a class="goo-button is-primary is-large" :href="updateMembershipURL">
            Update Membership
          </a>
        </div>
      </div>
      <div v-else>
        <p>
          Status: <strong>Inactive</strong>
        </p>

        <div class="goo-button-group">
          <a class="goo-button is-primary is-large" :href="updateMembershipURL">
            Join Membership
          </a>
        </div>
      </div>
    </div>

    <hr class="goo-divider is-large">

    <p class="membership-help-message">
      Want to cancel or pause your membership?
      <a target="_blank" href="https://help.gooroo.com/hc/en-us/requests/new" class="goo-link">Contact us</a>
    </p>
  </div>
</template>

<script>
  import { formatDate, formatCurrency } from "@gooroo-life/goo-formatters";

  const STATUSES = {
    active: "Active",
    canceled: "Cancelled",
    past_due: "PastDue",
    paused: "Paused"
  };

  export default {
    name: "MembershipDetails",
    components: {},
    filters: {
      formatDate,
      formatCurrency,
      formatStatus: function (value) {
        return STATUSES[value] || value;
      },
      formatHours: function (value) {
        const formatter = Intl.NumberFormat("en-US");

        if (value === 1) {
          return `${formatter.format(value)} hour`;
        }

        return `${formatter.format(value)} hours`;
      }
    },
    props: {
      membership: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        updateMembershipURL: process.env.VUE_APP_UPDATE_MEMBERSHIP_URL
      };
    },
    computed: {
      subscription () {
        return this.membership.subscription;
      }
    }
  };
</script>

<style lang="scss">
  .membership-details {
    max-width: 600px;

    &-content {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr;

      @media only screen and (min-width: 800px) {
        grid-template-columns: 8fr 4fr;
        grid-column: 1;

        // Change column order
        & > div:nth-child(1) { order: 1; }
      }
    }

    &-balance {
      border-radius: 4px;
      background: #ebebeb;
      padding: 16px 24px;
      text-align: center;
    }
  }
</style>
