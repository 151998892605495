import gql from "graphql-tag";

export const GET_MEMBERSHIP_STATUS = gql`
  query GetMembershipStatus {
    membershipStatus {
      balance
      subscription {
        status
        renewalDate
        isPremium
        plan {
          hours
          price
        }
      }
    }
  }
`;
