<template>
  <div class="account-settings" style="position: relative">
    <h1 class="heading-3">
      Membership details
    </h1>

    <MembershipDetails v-if="membershipStatus" :membership="membershipStatus" />
    <GooBodyTextSkeleton v-else :lines="4" />
  </div>
</template>

<script>
  import MembershipDetails from "@/components/Settings/tabs/Membership/components/MembershipDetails";
  import { GET_MEMBERSHIP_STATUS } from "@/components/Settings/tabs/Membership/queries";

  export default {
    name: "MembershipScreen",
    components: { MembershipDetails },
    data () {
      return {
        membershipStatus: null
      };
    },
    apollo: {
      membershipStatus: GET_MEMBERSHIP_STATUS
    }
  };
</script>

<style lang="scss">

</style>
